<template>
<div>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li class="nav-item" v-if="user_data.idStore == 1">
                    <a class="nav-link fw-bolder" :class="{'active': tabTipeProduk == 'mto', 'disabled': loadingTable}" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#mto" @click="!loadingTable && (tabTipeProduk = 'mto')">MTO</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tabTipeProduk == 'rtw', 'disabled': loadingTable}" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#rtw" @click="!loadingTable && (tabTipeProduk = 'rtw')">RTW</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tabTipeProduk == 'display', 'disabled': loadingTable}" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#display" @click="!loadingTable && (tabTipeProduk = 'display')">DISPLAY</a>
                </li>
            </ul>
            <div class="d-flex p-0">
                <div class="card-title row text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item" v-if="tabTipeProduk == 'mto'">
                            <span>DESAIN ID</span>
                            <el-input v-model="filter.desain_id" placeholder="Search Desain ID" clearable @change="onFilter('product')" />
                        </div>
                        <div class="filter-item">
                            <span>KATEGORI</span>
                            <el-select v-model="filter.category_id" placeholder="Pilih Kategori" clearable @change="onFilter('product')">
                                <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="filter-item" v-if="tabTipeProduk == 'mto'">
                            <span>FOLDER</span>
                            <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable @change="onFilter('product')">
                                <el-option v-for="(o, i) in listFolderProduct" :key="i" :value="o.id" :label="o.name">
                                    <span style="float: left">{{ o.name }}</span>
                                    <span style="float: right">({{ o.total }})</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="filter-item">
                          <span>BRAND</span>
                          <el-select v-model="filter.brand_id" placeholder="Pilih Brand" clearable @change="onFilter('product')">
                            <el-option v-for="(o, i) in listBrand" :key="i" :value="o.id" :label="o.name"/>
                          </el-select>
                        </div>
                        <div class="filter-item">
                            <span>SEARCH</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable @change="onFilter('product')" />
                        </div>
                        <div class="row gx-2">
                            <div class="col">
                                <button class="btn btn-icon btn-light-primary" @click="onFilter('product')"><i class="fas fa-search"></i></button>
                            </div>
                            <div class="col">
                                <button class="btn btn-icon btn-light-danger" @click="resetFilter('product')"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-3 fs-6 p-0 text-gray-600 text-uppercase" v-if="filterActive" v-show="false">
                        <span>Hasil filter</span>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" v-show="false">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                        <button class="btn btn-primary" @click="modalClick('add', null)">Create Product</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body tab-content" id="tab_main">
            <div class="tab-pane fade" :class="{active: tabTipeProduk == 'mto', show: tabTipeProduk == 'mto'}" id="mto" role="tabpanel" v-if="user_data.idStore == 1">
                <div class="table-fixed-header" ref="tableRef">
                    <table class="table table-rounded table-row-bordered table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-bottom-2 border-gray-200 text-uppercase">
                                <th>#</th>
                                <th>Gambar</th>
                                <th>Desain ID</th>
                                <th>Kategori</th>
                                <th class="text-center">Warna</th>
                                <th class="w-125px">Size</th>
                                <th>Voucher</th>
                                <th class="text-end w-125px">HPP (item+desain)</th>
                                <th class="text-end w-125px">Harga Tag</th>
                                <th class="text-end w-125px">Harga Retail</th>
                                <th class="text-end w-125px">Harga Wholesale</th>
                                <th class="text-end w-125px">Action</th>
                            </tr>
                        </thead>
                        <tbody class="fw-bold text-gray-600">
                            <tr v-for="(item, i) in tableData" :key="i" @click="router.push('/produksi/product/' + tabTipeProduk + '/' +item.category_id +'-'+ `${item.desain_id ? item.desain_id : item.artikel}`)" class="cursor-pointer">
                                <td scope="row">{{ i+1 }}</td>
                                <td><img v-if="tabTipeProduk == 'mto'" class="tbl-image" :src="img_url_desain+item.gambar" @click.stop.prevent="viewImage(item)"/></td>
                                <td>{{ item.desain_id }}</td>
                                <td>{{ item.category }}</td>
                                <td class="text-center">
                                    <el-popover
                                        placement="top"
                                        :width="250"
                                        trigger="hover"
                                        :content="item.color ? item.color : 'Tidak ada'"
                                    >
                                        <template #reference>
                                            <button href="javascript:void(0)" class="btn btn-secondary btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">Warna</button>
                                        </template>
                                    </el-popover>
                                </td>
                                <td>{{ item.size }}</td>
                                <td>
                                    <el-popover
                                        placement="top"
                                        :width="250"
                                        trigger="hover"
                                        :content="item.voucher_name ? item.voucher_name : 'Tidak ada'"
                                    >
                                        <template #reference>
                                            <button href="javascript:void(0)" class="btn btn-danger btn-active-light-primary btn-sm w-90px" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">{{ item.voucher_name ? 'Voucher' : '-' }}</button>
                                        </template>
                                    </el-popover>
                                </td>
                                <td class="text-end">{{ item.hpp ? formatIDR(item.hpp, { prefix: 'Rp'}) : 0 }}</td>
                                <td class="text-end">{{ formatIDR(item.price, { prefix: 'Rp'}) }}</td>
                                <td class="text-end">{{ formatIDR(item.discount_price, { prefix: 'Rp'}) }}</td>
                                <td class="text-end">{{ formatIDR(item.reseller_price, { prefix: 'Rp'}) }}</td>
                                <td class="indicator-arrow text-end">
                                    <router-link :to="'/produksi/product/' + tabTipeProduk + '/' +item.category_id +'-'+ item.desain_id" class="btn btn-light-primary btn-sm mb-2">
                                        View
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                        <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </table>
                </div>
            </div>
            <div class="tab-pane fade" id="rtw" :class="{active: tabTipeProduk == 'rtw', show: tabTipeProduk == 'rtw'}" role="tabpanel">
                <div class="table-fixed-header" ref="tableRef">
                    <table class="table table-rounded table-row-bordered table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-bottom-2 border-gray-200 text-uppercase">
                                <th>#</th>
                                <th>Gambar</th>
                                <th>Artikel</th>
                                <th>Kategori</th>
                                <th class="text-center">Warna</th>
                                <th class="w-125px">Size</th>
                                <th class="text-center">Voucher</th>
                                <th class="text-end w-125px">HPP (item+desain)</th>
                                <th class="text-end w-125px">Harga Tag</th>
                                <th class="text-end w-125px">Harga Retail</th>
                                <th class="text-end w-125px">Harga Wholesale</th>
                                <th class="text-end w-125px">Action</th>
                            </tr>
                        </thead>
                        <tbody class="fw-bold text-gray-600">
                            <tr v-for="(item, i) in tableData" :key="i" @click="router.push('/produksi/product/' + tabTipeProduk + '/' +item.category_id +'-'+ `${item.desain_id ? item.desain_id : item.artikel}`)" class="cursor-pointer">
                                <td scope="row">{{ i+1 }}</td>
                                <td><img v-if="tabTipeProduk == 'rtw'" class="tbl-image" :src="img_url_product+item.gambar" @click.stop.prevent="viewImage(item)"/></td>
                                <td>{{ item.artikel }}</td>
                                <td>{{ item.category }}</td>
                                <td class="text-center">
                                    <el-popover
                                        placement="top"
                                        :width="250"
                                        trigger="hover"
                                        :content="item.color ? item.color : 'Tidak ada'"
                                    >
                                        <template #reference>
                                            <button href="javascript:void(0)" class="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">Detail Warna</button>
                                        </template>
                                    </el-popover>
                                </td>
                                <td>{{ item.size }}</td>
                                <td>
                                    <el-popover
                                        placement="top"
                                        :width="250"
                                        trigger="hover"
                                        :content="item.voucher_name ? item.voucher_name : 'Tidak ada'"
                                    >
                                        <template #reference>
                                            <button href="javascript:void(0)" class="btn btn-danger btn-active-light-primary btn-sm w-90px" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">{{ item.voucher_name ? 'Voucher' : '-' }}</button>
                                        </template>
                                    </el-popover>
                                </td>
                                <td class="text-end">{{ item.hpp ? formatIDR(item.hpp, { prefix: 'Rp'}) : 0 }}</td>
                                <td class="text-end">{{ formatIDR(item.price, { prefix: 'Rp'}) }}</td>
                                <td class="text-end">{{ formatIDR(item.discount_price, { prefix: 'Rp'}) }}</td>
                                <td class="text-end">{{ formatIDR(item.reseller_price, { prefix: 'Rp'}) }}</td>
                                <td class="indicator-arrow text-end">
                                    <router-link :to="'/produksi/product/' + tabTipeProduk + '/' + item.category_id +'-'+ item.artikel" class="btn btn-light-primary btn-sm mb-2">
                                        View
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                        <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </table>
                </div>
            </div>
            <div class="tab-pane fade" id="display" :class="{active: tabTipeProduk == 'display', show: tabTipeProduk == 'display'}" role="tabpanel">
                <div class="table-fixed-header" ref="tableRef">
                    <table class="table table-rounded table-row-bordered table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-bottom-2 border-gray-200 text-uppercase">
                                <th>#</th>
                                <th>Gambar</th>
                                <th>Artikel</th>
                                <th>Kategori</th>
                                <th class="text-center">Warna</th>
                                <th class="w-125px">Size</th>
                                <th class="text-center">Voucher</th>
                                <th class="text-end w-125px">HPP (item+desain)</th>
                                <th class="text-end w-125px">Harga Tag</th>
                                <th class="text-end w-125px">Harga Retail</th>
                                <th class="text-end w-125px">Harga Wholesale</th>
                                <th class="text-end w-125px">Action</th>
                            </tr>
                        </thead>
                        <tbody class="fw-bold text-gray-600">
                            <tr v-for="(item, i) in tableData" :key="i" @click="router.push('/produksi/product/' + tabTipeProduk + '/' +item.category_id +'-'+ `${item.desain_id ? item.desain_id : item.artikel}`)" class="cursor-pointer">
                                <td scope="row">{{ i+1 }}</td>
                                <td><img v-if="tabTipeProduk == 'display'" class="tbl-image" :src="img_url_desain+item.gambar" @click.stop.prevent="viewImage(item)"/></td>
                                <td>{{ item.artikel }}</td>
                                <td>{{ item.category }}</td>
                                <td class="text-center">
                                    <el-popover
                                        placement="top"
                                        :width="250"
                                        trigger="hover"
                                        :content="item.color ? item.color : 'Tidak ada'"
                                    >
                                        <template #reference>
                                            <button href="javascript:void(0)" class="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">Detail Warna</button>
                                        </template>
                                    </el-popover>
                                </td>
                                <td>{{ item.size }}</td>
                                <td>
                                    <el-popover
                                        placement="top"
                                        :width="250"
                                        trigger="hover"
                                        :content="item.voucher_name ? item.voucher_name : 'Tidak ada'"
                                    >
                                        <template #reference>
                                            <button href="javascript:void(0)" class="btn btn-danger btn-active-light-primary btn-sm w-90px" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">{{ item.voucher_name ? 'Voucher' : '-' }}</button>
                                        </template>
                                    </el-popover>
                                </td>
                                <td class="text-end">{{ item.hpp ? formatIDR(item.hpp, { prefix: 'Rp'}) : 0 }}</td>
                                <td class="text-end">{{ formatIDR(item.price, { prefix: 'Rp'}) }}</td>
                                <td class="text-end">{{ formatIDR(item.discount_price, { prefix: 'Rp'}) }}</td>
                                <td class="text-end">{{ formatIDR(item.reseller_price, { prefix: 'Rp'}) }}</td>
                                <td class="indicator-arrow text-end">
                                    <router-link :to="'/produksi/product/' + tabTipeProduk + '/' + item.category_id +'-'+ item.artikel" class="btn btn-light-primary btn-sm mb-2">
                                        View
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                        <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </table>
                </div>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="totalPage" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="currentPage"
                />
            </div>
        </div>

        <el-dialog v-model="modalAdd" @close="filterActive = false" :title="tabTipeProduk == 'mto' ? 'Create Product MTO (pilih raw desain)' : 'Create Product RTW'" :close-on-click-modal="true" fullscreen custom-class="dialogMainProduct">
            <div class="mb-5" ref="tabRef">
                <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-6">
                    <li class="nav-item" v-if="user_data.idStore == 1">
                        <a class="nav-link" :class="{'active': tabTipeProduk == 'mto'}" data-bs-toggle="tab" href="#mto" @click="tabTipeProduk = 'mto'">MTO</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-h4" :class="{'active': tabTipeProduk == 'rtw'}" data-bs-toggle="tab" href="#rtw" @click="tabTipeProduk = 'rtw'">RTW</a>
                    </li>
                </ul>
            </div>
            
            <div class="tab-content sticky" id="tab_main_product">
                <div class="tab-pane fade show" :class="{active: tabTipeProduk == 'mto'}" @click="tabTipeProduk = 'mto'" id="mto" role="tabpanel">
                    <form @submit.prevent="onFilter('mto')" class="filter align-items-center position-relative fs-6" ref="searchRef">
                        <div class="filter-item">
                            <span>DESAIN ID</span>
                            <el-input v-model="filter.desain_id" placeholder="Desain ID" clearable @change="onFilter('mto')" />
                        </div>
                        <div class="filter-item">
                            <span>JENIS</span>
                            <el-select v-model="filter.jenis_desain" placeholder="Pilih Jenis" clearable @change="onFilter('mto')">
                                <el-option v-for="(o, i) in listJenisDesain" :key="i" :value="o.jenis" :label="o.jenis"/>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>FOLDER</span>
                            <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable @change="onFilter('mto')">
                                <el-option v-for="(o, i) in listFolderDesain" :key="i" :value="o.id" :label="o.name">
                                    <span style="float: left">{{ o.name }}</span>
                                    <span style="float: right">({{ o.total }})</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="filter-item" v-show="false">
                            <span>Release</span>
                            <el-select v-model="filter.release" placeholder="Product release" clearable @change="onFilter('rtw')">
                                <el-option :value="true" label="Full Release">
                                    <span>Full release</span>
                                </el-option>
                                <el-option :value="false" label="Belum Release">
                                    <span>Belum release</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="">
                            <button type="submit" class="btn btn-icon btn-light-primary me-3"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('mto')"><i class="fas fa-times"></i></button>
                        </div>
                    </form>
                    <div class="mb-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive" v-show="false">
                        <span>Hasil filter</span>
                    </div>
                    <div class="item mt-2" ref="itemRef" v-if="listMtoProduct.length > 0">
                        <div v-for="(item, index) in listMtoProduct" :key="index" class="item-list rounded me-4 mb-4" @click="getDetailMto('add', item)" style="outline: solid 1px #aaa; outline-offset: -1px; cursor: default;">
                            <div class="item-header text-center">
                                <h6>{{ item.folder }}</h6>
                            </div>
                            <div class="item-image mb-2">
                                <img v-if="tabTipeProduk == 'mto'" :src="img_url_desain+item.gambar" />
                                <div class="stack-top left" v-if="item.release_product == 0">BELUM RELEASE</div>
                                <span class="d-none">{{ item.tipe_desain ? item.tipe_desain : 'TIDAK ADA' }}</span>
                            </div>
                            <div class="item-name text-center">
                                <div class="fw-bolder text-dark">{{ item.sku }}</div>
                                <div class="fw-bolder text-dark">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <img class="data-not-found" src="@/assets/images/absurd/patrick-not-found.png"/>
                    </div>
                </div>
                
                <div class="tab-pane fade show" :class="{active: tabTipeProduk == 'rtw'}" @click="tabTipeProduk = 'rtw'" id="rtw" role="tabpanel">
                   <form @submit.prevent="onFilter('rtw')" class="filter position-relative mb-4 fs-6 fw-bolder text-gray-600" ref="searchRef">
                     <div class="filter-item">
                       <span>Brand</span>
                       <el-select v-model="filter.brand_id" placeholder="Pilih Brand" clearable @change="onFilter('rtw')">
                         <el-option v-for="(o, i) in listBrand" :key="i" :value="o.id" :label="o.name">
                           <span style="float: left">{{ o.name }}</span>
                         </el-option>
                       </el-select>
                     </div>
                     <div class="filter-item">
                       <span>Release</span>
                       <el-select v-model="filter.release" placeholder="Product release" clearable @change="onFilter('rtw')">
                         <el-option :value="true" label="Full Release">
                           <span>Full release</span>
                         </el-option>
                         <el-option :value="false" label="Belum Release">
                           <span>Belum release</span>
                         </el-option>
                       </el-select>
                     </div>
                       <div class="filter-item">
                         <span>Kategori</span>
                         <el-select v-model="filter.category_id" placeholder="Pilih Kategori" clearable @change="onFilter('rtw')">
                           <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name">
                             <span style="float: left">{{ o.name }}</span>
                           </el-option>
                         </el-select>
                       </div>
                        <div class="filter-item">
                            <span>Artikel</span>
                            <el-input v-model="filter.artikel" placeholder="Pilih Artikel" clearable @change="onFilter('rtw')" />
                        </div>
                        <div class="filter-item">
                           <span>SKU</span>
                           <el-input v-model="filter.sku" placeholder="Pilih SKU" clearable @change="onFilter('rtw')" />
                       </div>
                        <div class="">
                            <button type="submit" class="btn btn-icon btn-light-primary me-3"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('rtw')"><i class="fas fa-times"></i></button>
                        </div>
                    </form>
                    <div class="mb-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive" v-show="false">
                        <span>Hasil filter</span>
                    </div>
                    <div class="item mt-2" ref="itemRef" v-if="listRtwProduct.length > 0">
                        <div v-for="(item, index) in listRtwProduct" :key="index" class="item-list" @click="getDetailRtw('add', item)">
                            <div class="item-image mb-2">
                                <img  v-if="tabTipeProduk == 'rtw'" :src="img_url_product+item.gambar" />
                                <div style="background: var(--bg-primary);" class="bg-primary stack-top left" v-if="item.total_sku == item.total_product">FULL RELEASE</div>
                                <div style="background: var(--bg-primary);" class="bg-danger stack-top left" v-else-if="item.total_product == 0">BELUM RELEASE</div>
                              <div class="stack-top left bg-secondary" v-else>RELEASE</div>
                                <span class="d-none">{{ item.tipe_desain ? item.tipe_desain : 'TIDAK ADA' }}</span>
                            </div>
                            <div class="item-name mb-2 row">
                                <span>{{ item.raw_category }}</span>
                                <span>{{ item.artikel }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="text-center">
                    <el-pagination 
                        background layout="prev, pager, next" 
                        :page-count="totalPageAdd" 
                        @next-click="handlePageChangeAdd" 
                        @prev-click="handlePageChangeAdd"
                        @current-change="handlePageChangeAdd"
                        @update:current-page="currentPageAdd"
                    />
                </div>
            </template>
        </el-dialog>

        <el-dialog v-model="modalCreate" :title="modalType.toUpperCase() == 'ADD' ? 'Create Product (' + ((tabTipeProduk == 'mto') ? tempData.desain_id : tempData.artikel) + ')' : 'Edit Product'" width="80%" custom-class="dialogMainProductMto" :before-close="handleCancelCreate">
            <Form
                class="form w-100"
                :validation-schema="tempData"
                @submit="onSubmit(modalType)"
            >
                <div class="row">
                    <div class="row d-flex col-12 mb-7">
                        <div class="col-4 px-3">
                            <img v-if="modalCreate" :src="img_url_base+tempData.gambar" @click="viewImage({name: 'DESAIN '+tempData.desain_id, gambar: tempData.gambar, })" class="w-100 cursor-pointer" style="max-height: 700px; object-fit: cover;"/>
                        </div>
                        <div class="col px-3">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">Tipe Produk</label>
                                <input v-bind="field" :value="tabTipeProduk.toUpperCase()" class="form-control" disabled />
                            </div>
                            <div class="fv-row mb-7" v-if="tabTipeProduk == 'mto'">
                                <label class="required fs-6 fw-bold mb-2">Desain ID</label>
                                <input v-bind="field" :value="tempData.desain_id" class="form-control" disabled/>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">Nama (Optional)</label>
                                <input type="text" class="form-control" v-model="tempData.name" placeholder="Masukkan nama (Optional)" />
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">Kategori</label>
                                <Field v-if="tabTipeProduk == 'mto' && modalType == 'Add'" v-model="tempData.category_id" data-control="select2" data-placeholder="Pilih produk item" name="category_id" validateOnChange>
                                    <el-select 
                                        v-model="tempData.category_id" 
                                        class="w-100" 
                                        placeholder="Select"
                                        size="large" 
                                        @change="getColor($event), getCategoryPrice($event)"
                                        name="category_id"
                                    >
                                        <el-option
                                            v-for="(item, index) in listDesainKategori" 
                                            :key="index"
                                            :value="item.category_id"
                                            :category-id="item.category_id" 
                                            :category="item.category"
                                            :label="item.category"
                                            class="custom-list-dropdown"
                                        />
                                    </el-select>
                                </Field>
                                <Field v-else v-model="tempData.category_id" disabled as="select" class="form-select" data-control="select2" data-placeholder="Pilih produk item" @change="getCategoryPrice($event)" name="category_id" validateOnChange>
                                    <option disabled :value="tempData.category_id">{{ tempData.category }}</option>
                                </Field>
                                <div class="fv-plugins-message-container" v-if="!tempData.category_id">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="category_id" />
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">Varian Warna</label>
                                <Field v-if="tabTipeProduk == 'mto' && modalType == 'Add'" v-model="tempData.artikel" data-control="select2" data-placeholder="Pilih varian warna" name="artikel" validateOnChange>
                                    <el-select 
                                        v-model="tempData.artikel" 
                                        class="w-100" 
                                        placeholder="Select"
                                        size="large" 
                                        @change="getSize($event)"
                                        name="artikel"
                                    >
                                        <el-option
                                            v-for="(item, index) in listMtoVarian" 
                                            :key="index"
                                            :value="item.artikel"
                                            :color_id="item.color_id" 
                                            :color="item.color" 
                                            :price="item.price" 
                                            :artikel="item.artikel"
                                            :label="item.color + ' (' + item.artikel + ' - ' + item.vendor + ')'"
                                            class="custom-list-dropdown"
                                        >
                                            <el-popover
                                                placement="right"
                                                :width="250"
                                                trigger="hover"
                                            >
                                                <template #reference>
                                                    <span href="javascript:void(0)" class="w-100 d-flex flex-column" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                                        <div>{{ item.color }} ({{ item.artikel + ' - ' + item.vendor }})</div>
                                                        <div class="d-flex option-detail">
                                                            <small v-if="item.release_size" class="me-auto badge badge-light-primary">{{ item.release_size }}</small>
                                                            <small v-else><i>Size Belum Release</i></small>
                                                            <small v-if="!item.unrelease_size"><i>Full Release</i></small>
                                                        </div>
                                                    </span>
                                                </template>
                                                <div>
                                                    <h6>{{ item.color }} ({{ item.artikel + (item.vendor ? (' - ' + item.vendor ) : '') }})</h6>
                                                    <img class="w-100 cursor-pointer" :src="img_url_product+item.gambar" @click="viewImage({name: tempData.category + ' - ' + item.color + ' (' +item.artikel + ' - ' + item.vendor + ')', gambar: item.gambar, url_img: img_url_product })" />
                                                </div>
                                            </el-popover>
                                        </el-option>
                                    </el-select>
                                </Field>
                                <Field v-else disabled as="select" class="form-select" data-control="select2" data-placeholder="Pilih varian warna" v-model="tempData.artikel" name="artikel" validateOnChange>
                                    <option disabled :value="tempData.artikel">{{ tempData.color }} ({{ tempData.artikel + (tempData.vendor ? (' - ' + tempData.vendor ) : '') }})</option>
                                </Field>

                                <div class="fv-plugins-message-container" v-if="!tempData.artikel">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="artikel" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="fv-row mb-7">
                                <el-checkbox
                                    v-model="checkAllSize"
                                    :indeterminate="isIndeterminateSizes"
                                    @change="handlecheckAllSizeChange"
                                    :disabled="listMtoSize.length == 0 || onlyViewInput"
                                >
                                    Ukuran
                                </el-checkbox>
                                <el-checkbox-group
                                    v-if="modalType == 'Add'"
                                    v-model="mtoSize"
                                    @change="handleCheckedSizesChange"
                                >
                                    <el-checkbox v-for="size in listMtoSize" :key="size" :label="size">{{ size.size }}</el-checkbox>
                                </el-checkbox-group>

                                <el-checkbox-group v-else v-model="tempData.checked_size" disabled>
                                    <el-checkbox checked>{{ tempData.size }}</el-checkbox>
                                </el-checkbox-group>
                                
                                <div class="fv-plugins-message-container" v-if="mtoSize.length == 0 ">
                                    <div class="fv-help-block">
                                        <span>Size is required field</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2" title="Harga Pokok Penjualan">HPP (desain+polos)</label>
                                <input type="text" class="form-control" :value="formatIDR(tempData.hpp)" disabled />
                                <div class="fv-plugins-message-container" v-if="buildHpp.desain">
                                    <div class="">
                                        <span v-if="buildHpp.polos > 0">{{ formatIDR(buildHpp.desain) + ' + ' + formatIDR(buildHpp.polos) }}</span>
                                        <span v-else>{{ '(Polos belum dipilih)' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">Harga Tag</label>
                                <Field type="text" class="form-control" placeholder="Atur harga tag" v-model="tempData.price" name="price" :disabled="onlyViewInput" />
                                <div class="fv-plugins-message-container">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="price" />
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ retail_to_percent ? 'Diskon Persen (%)' : 'Harga Diskon (IDR)' }}</label><span class="ms-3" @click="retail_to_percent = !retail_to_percent"><i class="fas fa-exchange-alt"></i></span>
                                <Field type="text" class="form-control" placeholder="Atur harga diskon/retail" v-model="tempData.discount_price" name="discount_price" :disabled="onlyViewInput"/>
                                <div class="fv-plugins-message-container">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="discount_price" />
                                        <span v-if="tempData.artikel && tempData.price && tempData.discount_price" class="text-gray-700 fs-7 fw-bold">MARGIN: {{ formatIDR(calculateMargin(tempData.discount_price, retail_to_percent, 'margin'), { prefix: "Rp" }) + ' ('+ calculateMargin(tempData.discount_price, retail_to_percent, 'margin_percent').toFixed(2) + '%)' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ wholesale_to_percent ? 'Diskon Wholesale (%)' : 'Harga Wholesale (IDR)' }}</label><span class="ms-3" @click="wholesale_to_percent = !wholesale_to_percent"><i class="fas fa-exchange-alt"></i></span>
                                <Field type="text" class="form-control" placeholder="Atur harga wholesale" v-model="tempData.reseller_price" name="reseller_price" :disabled="onlyViewInput"/>
                                <div class="fv-plugins-message-container">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="reseller_price" />
                                        <span v-if="tempData.artikel && tempData.price && tempData.reseller_price" class="text-gray-700 fs-7 fw-bold">MARGIN: {{ formatIDR(calculateMargin(tempData.reseller_price, wholesale_to_percent, 'margin'), { prefix: "Rp" }) + ' ('+ calculateMargin(tempData.reseller_price, wholesale_to_percent, 'margin_percent').toFixed(2) + '%)' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">Voucher (Optional)</label>
                                <Field v-if="modalType == 'Add'" v-model="tempData.voucher_detail_id" data-control="select2" data-placeholder="Pilih voucher diskon" name="voucher_detail_id" validateOnChange>
                                    <el-select 
                                        v-model="tempData.voucher_detail_id" 
                                        class="w-100" 
                                        placeholder="Select"
                                        size="large"
                                        clearable
                                    >
                                        <el-option
                                            :value="''"
                                            :label="'Tidak ada'"
                                            class="custom-list-dropdown"
                                        />
                                        <el-option
                                            v-for="(item, index) in listVoucherDetail" 
                                            :key="index"
                                            :value="item.voucher_detail_id"
                                            :label="item.name + ' ('+ item.kode_voucher +')' + ' - ' + item.store"
                                            class="custom-list-dropdown"
                                        />
                                    </el-select>
                                </Field>
                                <Field v-else v-model="tempData.voucher_detail_id" disabled as="select" class="form-select" data-control="select2" data-placeholder="Pilih voucher diskon" @change="getCategoryPrice($event)" name="voucher_detail_id" validateOnChange>
                                    <option disabled :value="tempData.voucher_detail_id">{{ tempData.voucher_name }}</option>
                                </Field>
                                <div class="fv-plugins-message-container" v-if="!tempData.voucher_detail_id">
                                    <div class="fv-help-block">
                                        <ErrorMessage name="voucher_detail_id" />
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7 product-price-reference" v-if="Object.keys(categoryPrice).length > 0">
                                <div class="title">
                                    <div>* Referensi harga</div>
                                    <div>
                                        <a class="badge badge-primary" href="/master/price" target="blank">Setting Master</a>
                                    </div>
                                </div>
                                <ul>
                                    <li>
                                        <div class="list-row">
                                            <div>Harga Tag</div>
                                            <div>{{  !categoryPrice['price'] ? 'Belum ada data' : formatIDR(categoryPrice['price'], { prefix: 'Rp'}) }}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-row">
                                            <div>Harga Diskon</div>
                                            <div>{{ !categoryPrice['discount_price'] ? 'Belum ada data' : formatIDR(categoryPrice['discount_price'], { prefix: 'Rp'}) }}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="list-row">
                                            <div>Harga Wholesale</div>
                                            <div>{{ !categoryPrice['reseller_price'] ? 'Belum ada data' : formatIDR(categoryPrice['reseller_price'], { prefix: 'Rp'}) }}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-5 mb-8">
                        <div class="fv-row" v-if="tabTipeProduk == 'mto' && modalTitle != 'Edit'">
                            <label class="required fs-6 fw-bold">Gambar Mockup</label>
                            <div class="fv-plugins-message-container my-3" v-if="!tempData.gambar_mto">
                                <div class="fv-help-block">
                                    <span class="text-danger">Gambar mockup is required field</span>
                                </div>
                            </div>
                            <div class="list-mockup mt-5">
                                <div class="mockup row g-2">
                                    <div for="gambar_mto" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" v-for="ob in listMockupDesain" :key="ob" @click="!onlyViewInput && (tempData.gambar_mto = ob.gambar)">
                                        <input type="radio" class="radio-image" name="gambar_mto" v-model="tempData.gambar_mto" :value="ob.gambar" :disabled="onlyViewInput"/>
                                        <div>
                                            <img :src="img_url_desain+ob.gambar"/>
                                            <div class="text-center py-2">
                                                <span>{{ ob.category + ' - ' + ob.color}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 mb-2 text-center" v-if="!onlyViewInput">
                        <button class="btn btn-light me-3" @click="handleCancelCreate($event)">Cancel</button>
                        <button
                            type="submit"
                            ref="submitButton"  
                            class="btn btn-primary"
                        >
                            <span class="indicator-label"> Submit </span>

                            <span class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                    <div class="mt-4 mb-2 text-center">
                        <small><i>* Jangan lupa cek form input</i></small>
                    </div>
                </div>
            </Form>
        </el-dialog>
        
        <el-dialog v-model="getZoomImage.visibility" :title="getZoomImage.title" width="50%" lock-scroll top="4vh" align-center>
            <inner-image-zoom :src="getZoomImage.src" :zoomScale=".75" />
        </el-dialog>
    </div>

    <div class="bg-white mt-5 rounded-2" v-show="false">
        <div class="summary-total">
            <h4 class="mb-5 text-gray-700 text-uppercase">Ringkasan Produk Jadi {{ tabTipeProduk }} ({{ tabTipeProduk == 'mto' ? 'Made To Order' : 'Ready To Use' }})</h4>
            <div class="d-flex fs-4 fw-bolder text-gray-700">
                <div class="d-flex flex-column me-5">
                    <span>Total</span>
                    <span class="text-gray-400">Total</span>
                </div>
                <div class="d-flex flex-column">
                </div>
            </div>  
        </div>
    </div>
</div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    components: { 
        //Datatable,
        Field,
        Form,
        ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const route = useRoute()
        const router = useRouter()
      const store = useStore()
      const img_url_base = ref('')
      const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
      const img_url_product = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
      const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

      const loadingTable = ref(false);
      const tableRef = ref(null)

      const submitButton = ref(null);
      const modalAdd = ref(false)
      const modalCreate = ref(false)
      const modalRtw = ref(false)

        const user_data = computed(() => {
            return store.getters['currentUser'];
        })

      const filterActive = ref(false)
      const filter = reactive({
        desain_id: null,
        sku: null,
        folder_id: null,
        artikel: null,
        jenis_desain: null,
        category_id: null,
        brand_id: null,
        release: null,
        tipe_produk: null,
      })

      const onlyViewInput = ref(false)

      const retail_to_percent = ref(false)
      const wholesale_to_percent = ref(false)

      const tabTipeProduk = ref('mto')

      const tableHeader = ref([
          {
              name: "#",
              key: "index",
          },
          {
              name: "Mockup",
              key: "gambar",
          },
          {
              name: "Kategori",
              key: "category",
          },
          {
              name: "Warna",
              key: "color",
          },
          {
              name: "Ukuran",
              key: "size",
          },
          {
              name: "HPP (polos+desain)",
              key: "hpp",
          },
          {
              name: "Harga Tag",
              key: "price",
          },
          {
              name: "Harga Retail",
              key: "discount_price",
          },
          {
              name: "Harga Wholesales",
              key: "reseller_price",
          },
          {
              name: "Action",
              key: "action",
          },
        ]);

        // Custom validation Yup
        Yup.addMethod(Yup.string, "validDesain", function () {
            return this.test(`test-valid-desain`, 'Desain is not valid', function (value) {
                const { path, createError } = this;

                return (
                buildHpp.desain != 0 ||
                createError({ path, message: 'Desain is not valid' })
                );
            });
        });

        Yup.addMethod(Yup.string, "validPolos", function () {
            return this.test(`test-valid-polos`, 'Polos is not valid', function (value) {
                const { path, createError } = this;

                return (
                buildHpp.polos != 0 ||
                createError({ path, message: 'Polos is not valid' })
                );
            });
        });

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        /*
            START - CRUD PRODUK
        */

        const modalTitle = ref('')
        const modalType = ref('')

        const modalClick = async (type, row) => {
            const loading= ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.5)'
            })

            try {
                type == 'add' ? modalTitle.value = 'Add Product' : modalTitle.value = 'Edit Product'

                tempData.tipe_produk = tabTipeProduk.value

                if(type == 'add') {
                    tempData.gambar_mto = ''
                    
                    jenisDesain()
                    folderDesain()

                    tabTipeProduk.value == 'mto' 
                        ? await getMtoProduct()
                        : await getRtwProduct();

                    modalAdd.value = true
                }
            } catch(e) {
                console.log(e)
            }
            
            loading.close()
        }

        const handleCancelCreate = (done) => {
            ElMessageBox.confirm('Are you sure to cancel create product?')
            .then(() => {
                modalCreate.value = false
            })
            .catch(() => {
                // catch error
            })
        }

        const getDetailMto = async (type, val) => {
            listDesainKategori.splice(0)
            listMockupDesain.splice(0)
            listMtoVarian.splice(0)
            listMtoSize.splice(0)

            const loading= ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.5)'
            })

            let desain_id = type == 'add' ? val.sku : val.desain_id

            try {
                await ApiService.setHeader();
                await ApiService.get("desain_image/"+desain_id)
                .then(({ data }) => {
                    Object.assign(listMockupDesain, data)
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

                if(type == 'add') {
                    modalType.value = 'Add'

                    img_url_base.value = img_url_desain.value
                    
                    tempData.desain_id = val.sku
                    tempData.gambar = val.gambar
                    tempData.tipe_desain = val.tipe_desain
                    tempData.tipe_produk = 'MTO',
                    buildHpp.desain = val.price
                    tempData.price = null
                    tempData.discount_price = null
                    tempData.reseller_price = null
                    tempData.checked_size = true

                    tempData.hpp = parseFloat(buildHpp.desain) + parseFloat(buildHpp.polos)

                    await ApiService.setHeader();
                    await ApiService.get("product/get_desain_category/"+val.sku)
                    .then(({ data }) => {
                        Object.assign(listDesainKategori, data)
                    })
                    .catch(({ response }) => {
                        throw new Error('Terjadi error')
                    });

                } else {
                    if(type == 'edit') {
                        modalType.value = tabTipeProduk.value == 'mto' ? 'Edit (' + tempData.desain_id + ')' : 'Edit (' + tempData.artikel +')';
                        onlyViewInput.value = false
                    }
                    if(type == 'view') {
                        modalType.value = 'View'
                        onlyViewInput.value = true
                    }

                    img_url_base.value = img_url_desain.value

                    tempData.id = val.id
                    tempData.kode = val.kode
                    tempData.artikel = val.artikel
                    tempData.category = val.category
                    tempData.category_id = val.category_id
                    tempData.color = val.color
                    tempData.color_id = val.color_id
                    tempData.size = val.size
                    tempData.size_id = val.size_id
                    tempData.desain_id = val.desain_id
                    tempData.gambar = val.gambar
                    tempData.gambar_mto = val.gambar
                    tempData.tipe_desain = val.tipe_desain
                    tempData.tipe_produk = val.tipe_produk
                    tempData.hpp = val.hpp,
                    tempData.price = val.price,
                    tempData.discount_price = val.discount_price
                    tempData.reseller_price = val.reseller_price
                    tempData.checked_size = true
                    mtoSize.value = [1]
                }
                
                modalCreate.value = true

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
            
            loading.close()
        }

        const getDetailRtw = async (type, val) => {
          listMtoSize.splice(0)

          const loading= ElLoading.service({
              lock: true,
              text: 'Tunggu',
              background: 'rgba(255,255,255,0.5)'
          })

          try {
            if (type == 'add') {
              modalType.value = 'Add'

              img_url_base.value = img_url_product.value

              tempData.name = val.name
              tempData.gambar = val.gambar
              tempData.hpp = val.price
              tempData.tipe_produk = 'RTW'
              buildHpp.polos = val.price
              buildHpp.desain = 0 // price include polos+desain so this must set 0
              tempData.price = null
              tempData.discount_price = null
              tempData.reseller_price = null
              tempData.checked_size = true
              tempData.category = val.category
              tempData.category_id = val.category_id
              tempData.artikel = val.artikel
              tempData.color = val.color
              tempData.color_id = val.color_id

              await ApiService.setHeader();
              await ApiService.get("product/get_rtw_detail/" + val.artikel)
                .then(({ data }) => {
                  Object.assign(listMtoSize, data)

                  if (data.error) {
                    throw new Error(data.messages)
                  }
                })
                .catch(({ response }) => {
                  throw new Error(response.data.messages.error)
                });

            } else {
              if (type == 'edit') {
                modalType.value = 'Edit'
                onlyViewInput.value = false
              }
              if (type == 'view') {
                modalType.value = 'View'
                onlyViewInput.value = true
              }

              img_url_base.value = img_url_product.value

              tempData.id = val.id
              tempData.name = val.name
              tempData.kode = val.kode
              tempData.artikel = val.artikel
              tempData.category = val.category
              tempData.category_id = val.category_id
              tempData.color = val.color
              tempData.color_id = val.color_id
              tempData.size = val.size
              tempData.size_id = val.size_id
              tempData.desain_id = val.desain_id
              tempData.gambar = val.gambar
              tempData.gambar_mto = val.gambar
              tempData.tipe_desain = val.tipe_desain
              tempData.tipe_produk = val.tipe_produk
              tempData.hpp = val.hpp,
                tempData.price = val.price,
                tempData.discount_price = val.discount_price
              tempData.reseller_price = val.reseller_price
              tempData.checked_size = true
              mtoSize.value = [1]
            }

            modalCreate.value = true

          } catch(e) {
            console.log(e)
            Swal.fire({
              title: e.message,
              icon: "error",
              buttonsStyling: false,
              showConfirmButton: false,
              timer: 2000,
            })
          }
            
          loading.close()
        }

        const listRtwProduct = reactive([])
        const listMtoProduct = reactive([])
        const listDesainKategori = reactive([])
        const listMockupDesain = reactive([])
        const listMtoVarian = reactive([])
        const listVoucherDetail = computed(() => {
            return store.getters.getListVoucher
        })

        const itemRef = ref(null)
        const tabRef = ref(null)
        const searchRef = ref(null)

        const totalPage = ref(1)
        const currentPage = ref(1)

        const totalPageAdd = ref(1)
        const currentPageAdd = ref(1)

        const calculateMargin = (price, percent, type) => {
            let result = 0

            if(percent == true) {
                //result = ((((parseFloat(tempData.price) - ((parseFloat(tempData.price) * parseFloat(price)) / 100)) - parseFloat(tempData.hpp)) / parseFloat(tempData.hpp)) * 100) - 100
                type == 'margin' 
                    ? result = switchToPercent(price, false) - parseFloat(tempData.hpp)
                    : result = ((switchToPercent(price, false) - parseFloat(tempData.hpp)) / tempData.hpp) * 100
            } else {
                type == 'margin' 
                    ? result = (parseFloat(price) - parseFloat(tempData.hpp)) 
                    : result = ((parseFloat(price) - parseFloat(tempData.hpp)) / tempData.hpp) * 100
            }

            return result
        }

        // FILTER
        const listJenisDesain = reactive([])
        const listFolderDesain = reactive([])
        const listFolderProduct = reactive([])
        const listBrand = reactive([])
        const listCategory = reactive([])

        const jenisDesain = async () => {
            await ApiService.get("jenis_desain")
            .then(({ data }) => {
                //console.log(data)
                Object.assign(listJenisDesain, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list jenis desain');
            });
        }

        const folderDesain = async () => {
            listFolderDesain.splice(0)
            await ApiService.get("raw_folder")
            .then(({ data }) => {
                Object.assign(listFolderDesain, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list folder desain');
            });
        }

        const folderProduct = async () => {
            listFolderDesain.splice(0)
            await ApiService.get("product_folder")
            .then(({ data }) => {
                Object.assign(listFolderProduct, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list folder product');
            });
        }

        const getListBrand = async () => {
          try {
            listBrand.splice(0)
            await ApiService.get('brand')
              .then((response) => {
                Object.assign(listBrand, response.data)
              })
              .catch((error) => {
                console.log('Error getting list brand');
              })

          } catch(e) {
            console.log(e.message)
          }
        }

        const getCategory = async () => {
            try {
                listCategory.splice(0)
                await ApiService.get("category")
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }
                    Object.assign(listCategory, data)
                })
                .catch(({ response }) => {
                    throw new Error('Error getting list category')
                });

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const onFilter = async (type) => {
            if(Object.values(filter).every(o => o == null)) {
                //return
            }

            currentPageAdd.value = 1

            store.dispatch(Actions.ADD_FILTER, filter);

            itemRef.value && (itemRef.value.scrollTop = 0) // Reset scroll position
            filterActive.value = false

            if(type == 'mto') {
                await getMtoProduct()
            }

            if(type == 'rtw') {
                await getRtwProduct()
            }

            if(type == 'product') {
                currentPage.value = 1
                await productGroup()
            }

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          if(Object.values(filter).every(o => o == null || o.length == 0)) {
            //return
          }

          Object.keys(filter).map(key => filter[key] = null)
          filterActive.value = false

          if(type == 'mto') {
              const loading = ElLoading.service({
                  lock: true,
                  text: 'Tunggu',
                  background: 'rgba(255,255,255,0.7)'
              })

              await getMtoProduct()
              loading.close()
          }

          if(type == 'rtw') {
              const loading = ElLoading.service({
                  lock: true,
                  text: 'Tunggu',
                  background: 'rgba(255,255,255,0.7)'
              })

              await getRtwProduct()
              loading.close()
          }

          if(type == 'product') {
              await productGroup()
          }

        }

        // PAGINATION
         const handlePageChange = async (page) => {
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position
            await productGroup(page)
        }

        const pagination = ref({
          perPage: 50,
          currentPage: 1,
          totalPage: 1,
        })

      const handlePageChangeAdd = async (page) => {
            const loading= ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.5)'
            })

            itemRef.value && (itemRef.value.scrollTop = 0) // Reset scroll position

            tabTipeProduk.value == 'mto'
                ? await getMtoProduct(page)
                : await getRtwProduct(page)
            
            loading.close()
        }

        // TEMPORARY DATA
        const tempData = reactive(Yup.object().shape({
            id: '',
            name: '',
            sku: '',
            desain_id: '',
            category: '',
            category_id: Yup.string().required().label("Produk Item").nullable(true),
            artikel: Yup.string().required().label("Varian").nullable(true),
            color: '',
            color_id: '',
            hpp: 0,
            price: Yup.string().required().label("Harga Jual").nullable(true),
            discount_price: Yup.string().required().label("Harga Diskon").nullable(true),
            reseller_price: Yup.string().required().label("Harga Reseller").nullable(true),
            tipe_produk: '',
            tipe_desain: '',
            desain_gambar: '',
            gambar_mto: '',
            voucher_detail_id: null,
        }));

        const buildHpp = reactive({
            desain: 0,
            polos: 0
        })

        const getColor = async (val) => {
            try {
                // let category_id = e.target.value
                // let category = e.target.options[e.target.selectedIndex].attributes.category.value
                
                let getCategory = listDesainKategori.find(o => o.category_id == val)

                let category = getCategory.category
                let category_id = getCategory.category_id

                tempData.category = category

                listMtoVarian.splice(0)
                tempData.color_id = ''
                listMtoSize.splice(0)
                mtoSize.value.splice(0)

                tempData.artikel = ''
                tempData.color_id = ''
                buildHpp.polos = 0

                await ApiService.setHeader();
                await ApiService.get("product/get_color/"+tempData.desain_id+'/'+category_id+'/'+tabTipeProduk.value.toLowerCase())
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }
                    
                    Object.assign(listMtoVarian, data)
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const getSize = async (val) => {
            try {
                let getVarian = listMtoVarian.find(o => o.artikel == val)

                let artikel = getVarian.artikel
                let color = getVarian.color
                let color_id = getVarian.color_id
                let price = getVarian.price

                // let artikel = e.target.options[e.target.selectedIndex].attributes.artikel.value
                // let color = e.target.options[e.target.selectedIndex].attributes.color.value
                // let color_id = e.target.options[e.target.selectedIndex].attributes.color_id.value
                // let price = e.target.options[e.target.selectedIndex].attributes.price.value


                tempData.artikel = artikel
                tempData.color_id = color_id
                tempData.color = color
                buildHpp.polos = price

                listMtoSize.splice(0)
                mtoSize.value.splice(0)
                
                await ApiService.setHeader();
                await ApiService.get("product/get_size/"+artikel+'/'+tempData.desain_id)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Object.assign(listMtoSize, data)
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

                // Auto set mockup
                let getMockup = JSON.parse(JSON.stringify(listMockupDesain)).find(o => o.color_id == color_id && o.category_id == tempData.category_id);
                if(getMockup) {
                    tempData.gambar_mto = getMockup.gambar
                } else {
                    Swal.fire({
                        title: 'Mockup belum ada, silakan pilih manual.',
                        icon: "warning",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                }
                
                // listMockupDesain.splice(0)
                // Object.assign(listMockupDesain, getMockup);
            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        // MULTI CHECKBOX
        const checkAllColors = ref(false)
        const isIndeterminateColors = ref(true)
        const checkedColors = ref([
            { id: 4, name: 'black'},
        ])
        const colors = reactive([
            { id: 1, name: 'red'},
            { id: 2, name: 'green'},
            { id: 3, name: 'blue'},
            { id: 4, name: 'black'},
        ])

        const handleCheckAllColorsChange = (val) => {
            checkedColors.value = val ? colors : []
            isIndeterminateColors.value = false
        }
        const handleCheckedColorsChange = (val) => {
            const checkedCountColors = val.length
            checkAllColors.value = checkedCountColors === colors.length
            isIndeterminateColors.value = checkedCountColors > 0 && checkedCountColors < colors.length
        }

        const checkAllSize = ref(false)
        const isIndeterminateSizes = ref(true)
        const mtoSize = ref([])
        const listMtoSize = reactive([])

        const handlecheckAllSizeChange = (val) => {
            mtoSize.value = val ? listMtoSize : []
            isIndeterminateSizes.value = false
        }
        const handleCheckedSizesChange = (val) => {
            const checkedCountSizes = val.length
            checkAllSize.value = checkedCountSizes === listMtoSize.length
            isIndeterminateSizes.value = checkedCountSizes > 0 && checkedCountSizes < listMtoSize.length
        }

        // UPLOAD SINGLE FILE
        const file_mto = ref(null)
        const reader = new FileReader();
        const totalSize = ref(0);
        const currentProgress = ref(0);
        function handleEvent(event) {
            if (['loadend', 'load'].includes(event.type)) {
                currentProgress.value = 'Completed 100%';
            }
            if (event.type === 'progress') {
                currentProgress.value = `${(event.loaded / totalSize.value).toFixed(2) * 100}%`;
                //console.log('Loaded: '+event.loaded)
                //console.log('Progress: ', currentProgress.value);
                //console.log('Bytes transferred: ', event.loaded, 'bytes');
            }
            if (event.type === 'loadstart') {
                totalSize.value = event.total;
            }
        }
        function onFileChange(type) {
            var output = document.getElementById('preview-image');

            const fileMto = file_mto.value.files[0];
            tempData.gambar_mto = URL.createObjectURL(fileMto);

            output.onload = function() {
                //URL.revokeObjectURL(output.src) // free memory
            }
        }
        function changeImage(type) {
            tempData.gambar_mto = ''

            setTimeout(() => { 
                document.getElementById('fileMto').value = null
                document.getElementById('fileMto').click()
            }, 100);

        }

        const categoryPrice = reactive({})
        
        const getCategoryPrice = async (val) => {
            let category_id = val;

            try {
                Object.keys(categoryPrice).forEach(key => delete categoryPrice[key]);

                await ApiService.setHeader();
                await ApiService.get("price/category/"+category_id)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Object.assign(categoryPrice, data.data)

                    if(!Object.keys(categoryPrice).some(k => !categoryPrice[k] )) {
                        tempData.price = categoryPrice.price
                        tempData.discount_price = categoryPrice.discount_price
                        tempData.reseller_price = categoryPrice.reseller_price

                    } else {
                        tempData.price = null
                        tempData.discount_price = null
                        tempData.reseller_price = null
                    }
                })
                .catch((response) => {
                    console.log('Error getting detail category price');
                    throw new Error(response)
                });
            } catch(e) {
                console.log(e.message)
            }
        }

        const onSubmit = async (type) => {
            const formData = new FormData();
            var createMultiProduk = []
            let validInput = true
            let endpoint = ''

            try {
                // Take all price percent to nominal
                let discount = retail_to_percent.value ? switchToPercent(tempData.discount_price, false) : tempData.discount_price
                let reseller = wholesale_to_percent.value ? switchToPercent(tempData.reseller_price, false) : tempData.reseller_price
                
                if(type.toUpperCase() == 'ADD'){
                    endpoint = "product/create"

                    if(tabTipeProduk.value == 'mto' && !tempData.gambar_mto) {
                        throw new Error('Mockup is required')
                    }

                    if(mtoSize.value.length == 0) {
                        throw new Error('Size is required')
                    }
                    
                    for(let i=0; i<mtoSize.value.length; i++) {
                        createMultiProduk.push({
                            category: tempData.category,
                            category_id: tempData.category_id,
                            name: tempData.name,
                            qrcode: tempData.desain_id ? mtoSize.value[i].sku+'-'+tempData.desain_id : mtoSize.value[i].sku,
                            tipe: tempData.tipe_desain,
                            tipe_produk: tempData.tipe_produk,
                            kode: mtoSize.value[i].sku,
                            desain_id: tempData.desain_id,
                            artikel: tempData.artikel,
                            color: tempData.color,
                            color_id: tempData.color_id,
                            size: mtoSize.value[i].size,
                            size_id: mtoSize.value[i].size_id,
                            hpp: tempData.hpp,
                            gambar: tempData.gambar_mto ? tempData.gambar_mto : tempData.gambar,
                            price: tempData.price,
                            discount_price: discount,
                            reseller_price: reseller,
                            voucher_detail_id: tempData.voucher_detail_id,
                        })
                    }

                    //formData.append('image', tempData.gambar_mto ? file_mto.value.files[0] : null)
                    validInput = createMultiProduk.every(o => o)
                }
                else {
                    endpoint = "product/update/"+tempData.id

                    createMultiProduk.push({
                        id: tempData.id,
                        category: tempData.category,
                        category_id: tempData.category_id,
                        name: tempData.name,
                        qrcode: tempData.kode+'-'+tempData.desain_id,
                        tipe: tempData.tipe_desain,
                        kode: tempData.kode,
                        desain_id: tempData.desain_id,
                        artikel: tempData.artikel,
                        color: tempData.color,
                        color_id: tempData.color_id,
                        size: tempData.size,
                        size_id: tempData.size_id,
                        hpp: tempData.hpp,
                        gambar: tempData.gambar_mto,
                        price: tempData.price,
                        discount_price: discount,
                        reseller_price: reseller,
                    })
                }
                
                formData.append('data', JSON.stringify(createMultiProduk))
                formData.append('artikel', tempData.artikel)
                formData.append('tipe_produk', tempData.tipe_produk)
                
                if(validInput) {            
                    if(submitButton.value) {
                        submitButton.value.disabled = true;
                        submitButton.value.setAttribute("data-kt-indicator", "on");
                    }
                } else {
                    Swal.fire({
                        title: 'Error. Some field are required',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000,
                    })

                    return
                }

                await ApiService.post(endpoint, formData)
                .then(({ data }) => {
                  if(data) {
                      Swal.fire({
                          title: data.messages,
                          icon: "success",
                          buttonsStyling: false,
                          showConfirmButton: false,
                          timer: 2000,
                      })
                  }

                  mtoSize.value.splice(0)
                  //modalCreate.value = false

                  if(tempData.tipe_produk == 'RTW') {
                    modalCreate.value = false
                    getRtwProduct(currentPageAdd.value)
                  }

                  if(tempData.tipe_produk == 'MTO') {
                    tempData.category_id = null
                    tempData.artikel = null
                    tempData.color_id = null
                  }

                  tempData.price = null
                  tempData.discount_price = null
                  tempData.reseller_price = null
                  tempData.voucher_detail_id = ''

                  buildHpp.polos = 0
                })
                .catch(({ response }) => {
                    if(response) {
                        Swal.fire({
                            text: type == 'Add' ? "Failed Create Product" : "Failed Update Product",
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                });      

                loadingTable.value = true
                
                if(submitButton.value) {
                    submitButton.value.removeAttribute("data-kt-indicator");
                    submitButton.value.disabled = false;
                }
            
                loadingTable.value = false

            } catch(e) {
                console.error(e)
            }

            // Reset value
            //tempData.desain_id = null
        }

        /*
            END - CREATE PRODUK
        */

        const getZoomImage = reactive({
            title: '',
            src: '',
            visibility: false,
        })

        const viewImage = (data) => {
            getZoomImage.visibility = true
            getZoomImage.title = data.name

            if(data.url_img) {
                getZoomImage.src = data.url_img + data.gambar
            } else {
                getZoomImage.src = tabTipeProduk.value.toUpperCase() == 'MTO' ? img_url_desain.value+data.gambar : tabTipeProduk.value.toUpperCase() == 'RTW' ? img_url_product.value+data.gambar : img_url_desain.value+data.gambar;
            }
        }
        
        const onDelete = async (row) => {
            ElMessageBox.confirm(
                'Confirm delete '+row.name+'?',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                    center: false,
                }
            )
            .then(async () => {
                loadingTable.value = true

                await ApiService.setHeader();
                await ApiService.post("product/delete/"+row.id)
                .then(({ data }) => {
                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });

                    try {
                        tableData.filter((o, i) => {
                            if(o.id == row.id) {
                                tableData.splice(i, 1)
                            }
                        })

                        tableData.map((o, i) => o.index = i); // indexing
                    } catch {
                        window.location.reload();
                    }
                    
                    //removeObjectArray(tableData, item => item.id !== row.id); // Need reindexing (old method) 
                })
                .catch(({ response }) => {
                    if(response) {
                        Swal.fire({
                            title: response.error,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                });

                loadingTable.value = false
            })
            .catch(() => {
                //
            })
        }

        const getMtoProduct = async (page = 1) => {
          try {
            let page = currentPage.value ? '?page=' + currentPage.value : '?page=1';
            let desain_id = filter.desain_id ? '&desain_id=' + filter.desain_id : '';
            let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
            let jenis_desain = filter.jenis_desain ? '&jenis_desain=' + filter.jenis_desain : '';
            let search = filter.search ? '&search=' + filter.search : '';

            await ApiService.setHeader();
            await ApiService.get("product/list_raw_desain" + page + folder + jenis_desain + desain_id + search)
              .then(async ({ data }) => {
                await listMtoProduct.splice(0)
                currentPageAdd.value = 1
                Object.assign(listMtoProduct, data.list_data)
                totalPageAdd.value = data.total_page;
              })
              .catch(({ response }) => {
                console.log('Error getting raw desain');
              });
          } catch(e) {
            console.log(e)
          }
        }

        const getRtwProduct = async (page = 1) => {
             await ApiService.post("product/list_raw_rtw?page="+page, {filter: filter})
            .then(async ({ data }) => {
                await listRtwProduct.splice(0)
                Object.assign(listRtwProduct, data.data.data)
                currentPageAdd.value = data.data.pagination.page
                totalPageAdd.value = data.data.pagination.total_page;
            })
            .catch(({ response }) => {
                console.log('Error getting raw desain');
            });
        }

        const productGroup = async (page = 1) => {
          try {
            loadingTable.value = true
            tableData.splice(0)

            await ApiService.post("product?page=" + page, {
              filter: filter,
              tipe_produk: tabTipeProduk.value.toUpperCase()
            })
            .then(async ({ data }) => {
              await tableData.splice(0)
              Object.assign(tableData, data.data.data)

                currentPage.value = data.data.pagination.page
                totalPage.value = data.data.pagination.total_page;
            })
            .catch(({ response }) => {
              console.log('Error getting produk');
            });

          } catch(e) {
            console.log(e)
          }

          loadingTable.value = false
        }

        const stateFilter = () => {
          try {
            const getCurrentRoutePath = route.path.split('/')[1]
            // console.log(getCurrentRoutePath)
            // console.log(router.options.history.state.back)
            if(getCurrentRoutePath && router.options.history.state.back.includes(getCurrentRoutePath)) {
              Object.assign(filter, store.getters.getFilter);
            }
          } catch(e) {
            console.error(e)
          }
        }

        onBeforeMount(() => {
            setCurrentPageBreadcrumbs("Product Jadi", ["Product Jadi"]);
            
            stateFilter()

            if(filter.tipe_produk) {
              tabTipeProduk.value = filter.tipe_produk.toLowerCase()
            }

            productGroup()
            getCategory()
            folderProduct()
            getListBrand()

            store.dispatch(Actions.LIST_VOUCHER)
            
            tabTipeProduk.value == 'mto'
                ?   tableHeader.value.splice(3, 1, {
                        name: "Desain ID",
                        key: "desain_id",
                    })
                :   tableHeader.value.splice(3, 1, {
                        name: "Artikel",
                        key: "artikel",
                    })
        })

        watchEffect(() => {
            // Set height of scroll item
            if(itemRef.value && searchRef.value && tabRef.value) {
              let elDialogBody = document.querySelector(".dialogMainProduct > .el-dialog__body")
              let elDialogFooter = document.querySelector(".dialogMainProduct > .el-dialog__footer")

              //elDialogBody.style.height = window.innerHeight - (elDialogBody.offsetHeight - elDialogFooter.offsetHeight - searchRef.value.offsetHeight) + 'px'
              //itemRef.value.style.height = window.innerHeight - (itemRef.value.offsetHeight - searchRef.value.offsetHeight - tabRef.value.offsetHeight - 15) + 'px'

              //console.log(itemRef.value.offsetHeight + ', '+  searchRef.value.offsetHeight + ', '+ tabRef.value.offsetHeight + ', ' + window.innerHeight)
            }

            // Set Harga Pokok Penjualan
            tempData.hpp = parseFloat(buildHpp.desain) + parseFloat(buildHpp.polos)
            if(user_data.value.idStore != 1) {
              tabTipeProduk.value = 'rtw'
            }
        })

        watch(() => filter, (val) => {
          //store.dispatch(Actions.ADD_FILTER, val);
        }, { deep: true })

        watch(() => tabTipeProduk.value, (val) => {
          //filter.tipe_produk = val
            if(val) {
                filter.tipe_produk = val
                store.dispatch(Actions.ADD_FILTER, filter);
            }

            if(val == 'mto') {
                if(modalAdd.value) 
                    getMtoProduct()
                else
                    productGroup()

                tableHeader.value.splice(3, 1)
                tableHeader.value.splice(3, 0, {
                    name: "Desain ID",
                    key: "desain_id",

                })

             } else{

                if(modalAdd.value) 
                    getRtwProduct()
                else
                    productGroup()

                tableHeader.value.splice(3, 1)
                tableHeader.value.splice(3, 0, {
                    name: "Artikel",
                    key: "artikel",
                })
            }
        })

        watch(modalCreate, (val) => {
            if(!val) {
                // Reset all value input
                tempData.category_id = ''
                tempData.gambar = ''
                tempData.color_id = ''
                tempData.artikel = ''
                tempData.size_id = ''
                buildHpp.desain = 0
                buildHpp.polos = 0

                listDesainKategori.splice(0)
                listMockupDesain.splice(0)
                listMtoVarian.splice(0)
                listMtoSize.splice(0)
                mtoSize.value = []
            }
        })  

        const switchToPercent = (price, percent) => {
            let result = 0
            try {
                if(percent == true) {
                    result = (100 - ((parseFloat(price) / parseFloat(tempData.price)) * 100)) // to percent
                } else {
                    result = Math.ceil((parseFloat(tempData.price) - ((parseFloat(price) / 100) * parseFloat(tempData.price)))) // to idr
                }
            } catch {
                console.error('Error converting data')
            }

            return result
        }

        watch(retail_to_percent, (val) => {
            if(tempData.price) {
                tempData.discount_price = switchToPercent(parseFloat(tempData.discount_price), val)
            }
        })

        watch(wholesale_to_percent, (val) => {
            if(tempData.price) {
                tempData.reseller_price = switchToPercent(parseFloat(tempData.reseller_price), val)
            }
        })

        return {
            router,
            loadingTable, tableRef, img_url_base, img_url_mockup, img_url_product, img_url_desain, tabTipeProduk, itemRef, tabRef, searchRef,
            onFilter, resetFilter, filter, filterActive, listJenisDesain, listFolderDesain, listFolderProduct, listCategory, listBrand,
            tableHeader, tableData, totalRecordCount, retail_to_percent, wholesale_to_percent,
            tempData, listMtoProduct, listDesainKategori, listMockupDesain, listMtoVarian, listMtoSize, listVoucherDetail,
            getDetailMto, getColor, getSize, buildHpp, mtoSize,
            listRtwProduct, getDetailRtw,
            user_data,
            categoryPrice, getCategoryPrice,
            modalAdd, modalClick, modalTitle, modalCreate, modalType, onlyViewInput, handleCancelCreate,
            handlePageChange, totalPage, currentPage, pagination,
            handlePageChangeAdd, totalPageAdd, currentPageAdd,
            file_mto, onFileChange, changeImage, viewImage, getZoomImage,
            onSubmit, onDelete, submitButton,
            formatIDR, calculateMargin,
            isIndeterminateColors, colors, checkedColors, checkAllColors, handleCheckAllColorsChange, handleCheckedColorsChange,
            isIndeterminateSizes, checkAllSize, handlecheckAllSizeChange, handleCheckedSizesChange,
            InfoFilled,
        }
    }
})
</script>